<template>
  <div class="relative top-0 left-0 right-0 bg-white shrink-0">
    <div class="max-w-screen-xl mx-auto z-100 print:max-w-[720px]">
      <div class="flex w-full px-md">
        <div class="absolute flex items-center h-full text-vdv-base">
          <div
            class="flex flex-row-reverse gap-2 cursor-pointer place-items-center"
            @click="back"
          >
            <span class="anim__underline-link vdv-anim-link">
              {{ isMobile ? '' : t('subView.header.back') }}
            </span>
            <FaIcon icon-class="fas fa-chevron-left" />
          </div>
        </div>

        <div class="mx-auto ml-auto">
          <NuxtLink to="/">
            <img
              v-if="!isMobile"
              src="~/assets/images/van-der-ven-logo.svg"
              title="van der ven logo"
              alt="van der ven logo"
              class="h-[36px] my-3"
            />
            <img
              v-if="isMobile"
              src="~/assets/images/vdv-v-bubble.svg"
              title="van der ven logo"
              alt="van der ven logo"
              class="h-[36px] my-3"
            />
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
  <div
    class="relative bottom-0 left-0 right-0 h-2 bg-activesection-base print:h-[1px] print:max-w-[720px] mx-auto"
  />
</template>
<script setup lang="ts">
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import FaIcon from '@/components/fa-icon.vue';

const { t } = useTrans();

const isMobile = inject(mq_breakpointIsMobile);
const router = useRouter();

async function back() {
  if (history.state.back) router.back();
  else await router.push('/');
}
</script>
